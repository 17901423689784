import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import "./seamlessly-looping-art.css";
import mp4 from "./assets/Cyclic-800x450.mp4";
import webm from "./assets/Cyclic-800x450.webm";
import png from "./assets/Cyclic-800x450.png";

export default () => {
  const title = "Seamlessly Looping Art with Processing";
  const subtitle = "Gray Area Festival Workshop";
  return (
    <Layout>
      <SEO title={`${title} : ${subtitle}`} />

      <div className="Workshops">
        <video className="Workshops__banner" autoPlay loop>
          <source src={mp4} type="video/mp4" />
          <source src={webm} type="video/webm" />
          <img src={png} />
        </video>
        <header className="Workshops__header">
          <h1>{title}</h1>
          <h2>{subtitle} with Soma Holiday</h2>
          <div className="Workshops__dateTime">
            <div>October 4, 2020</div>
            <div>3PM - 6PM Pacific</div>
          </div>
        </header>
        <div className="Workshops__content">
          <h2>Workshop Resources</h2>
          <ul>
            <li>
              <a target="_blank" rel="noopener noreferrer" href="https://slides.com/somahol/seamlessly-looping-art/">
                Slides
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://gist.github.com/somaholiday/ad03c9445ac25046ba66dd74b84915be"
              >
                Code Exercise Solutions
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://gist.github.com/somaholiday/af9fc6f8eabc033dcab28b1d6fcad22c"
              >
                Processing Loop Template
              </a>
            </li>
            <li>
              <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=jYg3L85lPEE">
                Workshop Recording
              </a>
            </li>
          </ul>
          <p>Thanks to everyone who attended!</p>
          <p>
            <span role="img" aria-label="heart">
              💙
            </span>{" "}
            Soma
          </p>
        </div>
      </div>
    </Layout>
  );
};
